import api from "../axios_service.js";

export async function fetchClienteServicos(id) {
  const response = await api.get(`/gestor/clientes-servicos/${id}`);
  return response.data.result;
}

export async function getNotSelected(id) {
  const response = await api.get(`/gestor/servico-nao-selecionado/${id}`);
  return response.data.result;
}

export function postClienteServicos(local) {
  let body = {};
  for (let key in local) {
    body[key] = local[key];
  }
  return api.post("/gestor/clientes-servicos/add", body);
}

export function deleteClienteServicos(id) {
  return api.delete(`/gestor/clientes-servicos/${id}`);
}
