<template>
  <v-card :loading="loading" :disabled="disabled" flat>
    <v-card-title class="text-subtitle-1 accent py-3">
      Serviços Prestados a esse cliente
      <v-spacer></v-spacer>
      <v-btn
        small
        @click="openDialog"
        align="center"
        color="secondary"
        class="white--text ml-3"
      >
        Adicionar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="cliente_servicos.length" class="py-4">
      <div v-if="briefingComponent">
        <v-chip-group v-model="servicoSelecionado" column>
          <v-chip
            v-for="(servico, index) in cliente_servicos"
            :key="index"
            class="ma-1"
            label
            close
            close-icon="mdi-delete"
            @click:close="delClienteServicos(servico.id)"
            filter
            @click.stop
            :value="servico.servico_id"
          >
            <v-icon left>mdi-account</v-icon>
            {{ servico.servico }}
          </v-chip>
        </v-chip-group>
      </div>
      <div v-else-if="!briefingComponent">
        <v-chip
          v-for="(servico, index) in cliente_servicos"
          :key="index"
          class="ma-1"
          label
          close
          close-icon="mdi-delete"
          @click:close="delClienteServicos(servico.id)"
        >
          <v-icon left>mdi-account</v-icon>
          {{ servico.servico }}
        </v-chip>
      </div>
    </v-card-text>
    <v-card-text v-else class="pa-4">
      Nenhum serviço vinculado.
    </v-card-text>

    <v-dialog v-model="dialog" width="100%" max-width="400">
      <v-card
        :loading="loadingClienteServicos"
        :disabled="loadingClienteServicos"
      >
        <v-card-title class="text-subtitle-1 accent lightgray">
          Adicionar Serviço ao Cliente
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-10">
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-combobox
              hide-selected
              v-model="selectedServicos"
              :items="servicosNot"
              label="Serviços"
              item-text="descricao"
              item-value="id"
              dense
              return-object
              :rules="formRules"
            ></v-combobox>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!validForm"
            @click="createServicos"
            color="secondary"
            depressed
            class="white--text"
            small
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  fetchClienteServicos,
  postClienteServicos,
  deleteClienteServicos,
  getNotSelected,
} from "@/api/gestor/cliente_servicos.js";

import { inputRequired } from "@/helpers/utils.js";

export default {
  name: "ClienteServicosPrestados",

  props: {
    selectedServico_id: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    briefingComponent: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: [Number, String],
      require: true,
    },
  },

  data() {
    return {
      cliente_servicos: [],
      cliente_servico: {
        cliente_id: null,
        servico_id: null,
      },
      loading: false,
      dialog: false,
      validForm: true,
      loadingClienteServicos: false,
      selectedServicos: null,
      servicoSelecionado: null,
      servicosNot: [],

      formRules: [inputRequired],
    };
  },

  computed: {
    //
  },

  watch: {
    selectedServicos() {
      if (this.selectedServicos) {
        this.cliente_servico.servico_id = this.selectedServicos.id;
      }
    },
    servicoSelecionado() {
      if (this.servicoSelecionado) {
        this.$emit("servico-selecionado", this.servicoSelecionado);
      } else {
        this.$emit("servico-selecionado", this.servicoSelecionado);
      }
    },
  },

  methods: {
    openDialog() {
      this.getServicosNotSeleted();
      this.selectedServicos = null;
      this.dialog = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    getClienteServicos() {
      this.loading = true;
      return fetchClienteServicos(this.cliente_id)
        .then((response) => {
          this.cliente_servicos = response;
          if (this.briefingComponent) {
            this.$emit("cliente-servicos", this.cliente_servicos);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createServicos() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.cliente_servico.cliente_id = this.cliente_id;
        postClienteServicos(this.cliente_servico)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Serviço adicionado com sucesso");
              this.getClienteServicos();

              this.dialog = false;
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getServicosNotSeleted() {
      this.loading = true;
      getNotSelected(this.cliente_id)
        .then((response) => {
          this.servicosNot = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    delClienteServicos(id) {
      this.loading = true;
      deleteClienteServicos(id)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Serviço removido");
            if (this.briefingComponent) {
              this.servicoSelecionado = null;
            }

            this.getClienteServicos();
          }

          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$toast.error(
              "Este serviço não pode ser excluido por estar vinculado a um briefing"
            );
          }
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getClienteServicos();
    if (this.selectedServico_id) {
      this.servicoSelecionado = this.selectedServico_id;
    }
  },
};
</script>

<style lang="scss"></style>
